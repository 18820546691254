<template>
      <!-- Main Footer -->
  <footer class="main-footer mx-8 pb-4 mt-0"  v-animate-css="'slideInUp'">
    <strong>Copyright &copy; 2016-{{ new Date().getFullYear() }} <a :href="url">{{ company }}</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 2.0.2
    </div>
  </footer>

</template>
<script>
export default {
  computed: {
    company: function() {
        if(this.$store.getters.whitelist.company_name != null && this.$store.getters.whitelist.company_name != '') {
            return this.$store.getters.whitelist.company_name
        } else {
          return ''
        }
    },
    url: function() {
        if(this.$store.getters.whitelist.domain != null && this.$store.getters.whitelist.domain != '') {
            return this.$store.getters.whitelist.domain
        }
        else {
          return ''
        }
    }
  }
}
</script>

<style scoped>
footer {
  text-align: center;
  margin-top: 25px;
  color: #fff;
}
</style>