<template>
<div v-if="hasData" class="mt-8">
    <div class="intro-y box">
        <div class=" flex flex-row  justify-between p-5 border-b border-gray-200">
            <div class="flex flex-col">
                <h3 class="font-medium text-base mr-auto">{{ website.name }}</h3>
                <span>{{ website.url }}</span>
            </div>
            <div class=" flex items-center mt-3">
                <button class="mx-2" :class="{'active': statistics}" @click="statisticsView()">
                     <fa-icon :icon="['fa', 'chart-bar']" data-placement="right"  data-toggle="tooltip" title="Statistics" />
                 </button>
                <button class="mx-2" :class="{'active': shares}" @click="sharesView()">
                    <fa-icon :icon="['fa', 'percent']"  data-toggle="tooltip" data-placement="right" title="Shares" />
                </button>
            </div>
        </div>
        <div class="overlay" v-if="isLoading">
            <point-spinner />
        </div>
        <!-- /.card-header -->
        <div class="intro-y overflow-auto  mt-8 sm:mt-0">
            <table class="table table-report sm:mt-2">
                <thead>
                    <tr>
                      <th class=" whitespace-no-wrap">Category</th>
                      <th class=" whitespace-no-wrap">Description</th>
                      <th class=" whitespace-no-wrap">Status</th>
                      <th class=" whitespace-no-wrap">Total Ad Types</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ website.category }}</td>
                      <td><p v-html="website.description"></p></td>
                      <td>
                        <span class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" v-if="website.status" :class="{
                            'bg-theme-9': website.status == 'active',
                            'bg-theme-4': website.status == 'pending',
                            'bg-theme-6': website.status == 'suspended'}"> 
                            {{ website.status | capitalize }} 
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
            </table>            
        </div>
        <div class="" v-if="hasError">
            <fa-icon :icon="['fa', 'exclamation-triangle']" ></fa-icon>
        </div>
    </div>

    <Statistics 
    v-if="statistics"
    :website="website.id"
    group="all"
    :start_date="start_date" :end_date="end_date"
    :order="order" :limit="limit"
    :search="search"
    :in_website="true"
    />
    <Shares v-if="shares" :website="website" />

</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const WebsitesRepository = RepositoryFactory.get('websites')

import Statistics from '@/components/analytics/StatisticsInWebsite'
import Shares from './Shares'

export default {
    components: {
        Statistics,
        Shares
    },
    data() {
        return {
            website: {},
            isLoading: true,
            hasError: false,
            statistics: false,
            shares: false,

            start_date: "",
            end_date: "",
            order: "-date",
            limit: 30,
            search: 0,
        }
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip()
    },
    created() {
        if(typeof this.$route.params.id !== 'undefined' && !isNaN(this.$route.params.id)) {
            this.loadWebsite()
        }

        this.end_date = this.$moment().format('YYYY-MM-DD')
        this.start_date = this.$moment().subtract(15, 'days').format('YYYY-MM-DD')
    },
    methods: {
        loadWebsite: function() {
            this.isLoading = true
            var website_id = this.$route.params.id
            WebsitesRepository.get(website_id).then(result => {
                this.website = result.data
                this.isLoading = false
            }).catch(err => {
                this.website = {}
                this.isLoading = false
                this.hasError = true
            })
        },
        statisticsView: function() {
            if(this.statistics) {
                this.statistics = false
                this.shares = false
            } else {
                this.statistics = true
                this.shares = false
            }
        },
        sharesView: function() {
            if(this.shares) {
                this.shares = false
                this.statistics = false
            } else {
                this.shares = true
                this.statistics = false
            }
        },
    },
    computed: {
        hasData: function() {
            if(typeof this.$route.params.id !== 'undefined' && !isNaN(this.$route.params.id)) {
                return true
            }

            return false
        },
    },
    watch: {
        '$route.params.id': function (id) {
            if(typeof id !== 'undefined') {
                this.isLoading = true
                this.hasError = false
                this.loadWebsite()

                this.shares = false
                this.statistics = false
            }
        }
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style> 