<template>
<div>
    <div class="intro-y box mt-8">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="intro-x font-medium text-base mr-auto"> {{ website.name | capitalize }} <i v-if="isLoading" class="fa fa-spinner fa-pulse"></i></h2>
            <h2 class="font-medium text-base mr-auto" v-if="!website.id"> <i class="fa fa-arrow-left"></i> Please select a website </h2>

            <div class="intro-x relative">
                <div class="search hidden sm:block">
                    <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search ..." v-model="search_text">
                    <i data-feather="search" class="search__icon"></i>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card-header -->

        <div class="mt-5 ml-5" v-if="!isLoading && website.id && tags.length <= 0">
            <h5><fa-icon :icon="['fas', 'info']" /> No tags available for {{ website.name }}</h5>
        </div>

        <div v-if="isLoading">
            <point-spinner />
        </div>

        <div class="intro-y box p-5 mt-12 sm:mt-5" v-for="tag in tags" :key="tag.id">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <h3 class="card-title">{{ tag.name }}</h3>
                    <div class="card-tools">
                        <a href="#" class="btn btn-tool btn-sm" @click.prevent="copyTag(tag.code)"><i class="fa fa-copy"></i></a>
                    </div>
                </div>
                <div class="card-body">
                    <pre>
                    {{ tag.code }}
                    </pre>
                </div>
            </div>
        </div>
        <input type="hidden" id="clipboard-code" style="white-space: pre-line;" />

        <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3" v-if="tags.length > 0">
            <ul class="pagination">
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                </li>
                <li class="">
                    <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                </li>
            </ul>
        </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory';
const TagsRepo = RepositoryFactory.get('tags');

export default {
    props: {
        website: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tags: [],
            message: "",
            search_text: "",

            isLoading: false,
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
        }
    },
    methods: {
        loadTags: function(website_id) {
            this.isLoading = true
            TagsRepo.page(website_id, this.currentPage, this.resultsLimit, this.search_text).then(result => {
                this.totalRecords = result.data.count
                this.tags = result.data.results
                this.isLoading = false
            }).catch(error => {
                this.isLoading = false
            })
        },
        copyTag: function(tag_code) {
            let testingCodeToCopy = document.getElementById('clipboard-code')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.value = tag_code
            testingCodeToCopy.select()
            try {
                document.execCommand('copy')
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Clipboard",
                    text: "Code Copied to clipboard"
                })
            } catch {
                this.$notify({
                    group: "notifications",
                    type: 'warn',
                    title: "Clipboard",
                    text: "Failed to copy code"
                })
            }
            testingCodeToCopy.setAttribute('type', 'hidden')
        },
        loadSearchResult() {
            this.loadTags(this.website.id)
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        'website.id': function(website) {
            this.loadTags(website)
        },
        search_text: function() {
            this.loadSearchResult()
        }
    }
}
</script>

<style scoped>
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>