<template>
  <div class="col-span-12 mt-8">
    <div class="intro-y box">
      <div class="flex flex-row items-center sm:justify-between p-5 border-b border-gray-200">
        <div class="flex flex-row">
          <h2 class="font-medium text-base mr-auto">List</h2> 
          <router-link class=" ml-1 " :to="{name: 'new_website'}" >
            <i data-feather="plus" class="inline" data-toggle="tooltip" title="Submit website" data-placement="top" ></i>
          </router-link>
        </div>  
        <div class="intro-x relative">
          <div class="search hidden lg:block" >
            <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search" v-model="search_text">
            <button  @click.prevent="loadSearchResult()"><i data-feather="search" class="search__icon"></i> </button>
          </div>
        </div>  
      </div>
      <!-- /.card-header -->
      <div style="padding: 10px;">
        <ul class="nav nav-pills flex-column">
          <li class="items-center" v-if="isLoading">
            <point-spinner />
          </li>
          <li class=" intro-x" v-if="websites.length == 0">
            <a href="#" class="nav-link">
              No Available websites
            </a>
          </li>
          <li class=" intro-x" v-for="website in websites" :key="website.id">
            <router-link class="flex items-center p-3 cursor-pointer transition duration-300 ease-in-out bg-white dark:bg-dark-3 hover:bg-gray-200 rounded-md" :to="{name: 'website', params: {'id': website.id} }">
              <div class="pos__ticket__item-name truncate mr-1">{{ website.name }}</div>
              <div class="ml-auto">
                <span class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{
                    'bg-theme-9 ': website.status == 'active',
                    'bg-theme-4 ': website.status == 'pending',
                    'bg-theme-6 ': website.status == 'suspended'}"> {{ website.status | capitalize }} 
                </span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
    props: {
        websites: {
          required: true,
          type: Array
        },
        isLoading: {
          required: true,
          type: Boolean
        }
    },
    data() {
        return {
          search_text: "",
        }
    },
    mounted(){
      this.$feather.replace()
    },
    updated() {
      $('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
      loadSearchResult: function() {
        this.$emit("search", this.search_text)
      }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>