import Vue from 'vue'
import Router from 'vue-router'

// accounts pages
import Login from '@/components/account/Login.vue'

//import Signup from '@/components/account/Signup.vue'
import ForgotPassword from '@/components/account/ForgotPassword'
import ResetPassword from '@/components/account/ResetPassword'
import VerifiyAccount from '@/components/account/VerifiyAccount.vue'

// main pages
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard'

// Tags and Ads
import Tags from '@/views/Tags.vue'
import Ads from '@/views/Ads.vue'

// analytics
import Analytics from '@/views/Analytics.vue'

// websites pages
import Websites from '@/views/Websites.vue'
import NewWebsite from '@/components/websites/New.vue'
import Website from '@/components/websites/Website.vue'

// users pages
import UsersList from '@/components/users/List.vue'
import User from '@/components/users/Profile.vue'
import NewUser from '@/components/users/New.vue'
import Profile from '@/views/Profile.vue'

import Whitelist from '@/views/Whitelist.vue'

import Wallets from '@/views/Wallets.vue'
import Wallet from '@/components/wallets/Wallet.vue'

import AccountProfile from '@/components/account/profile/profile.vue'
import AccountBankAccount from '@/components/account/profile/bank.vue'
import AccountLogin from '@/components/account/profile/login.vue'
import UserAssignedWesites from '@/components/users/Assigned.vue'

import RequestNewFormat from '@/views/RequestNewFormat.vue'
import ReportAd from '@/views/ReportAd.vue'

// Notifications view
import Notifications from "@/components/notifications/Notifications.vue"
import Messages from "@/components/notifications/Messages.vue"

// errors
import Error404 from '@/views/Errors/404.vue'

//import Error505 from '@/views/Errors/505.vue'
import ErrorDenied from '@/views/Errors/Denied.vue'
import ErrorSuspended from '@/views/Errors/Suspended.vue'

import store from '@/store'

Vue.use(Router)

const logoutUser = (to, from, next) => {
  store.dispatch('AUTH_LOGOUT')
  next({name: 'login'})
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: route => ({ login: true }),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Login,
    props: route => ({ forgot: true }),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/reset/:key',
    name: 'reset',
    component: ResetPassword,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/verifiy/:key',
    name: 'verifiy',
    component: VerifiyAccount,
    meta: { 
      requiresAuth: false
    }
  },
  {
    // the main user dashboard
    path: '/',
    component: Home,
    meta: { 
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'websites/:id?',
        name: 'websites',
        component: Websites,
        children: [
          {
            path: '',
            name: 'website',
            component: Website
          },
          {
            path: 'new',
            name: 'new_website',
            component: NewWebsite
          },
        ]
      },
      {
        path: 'tags',
        name: 'tags',
        component: Tags,
      },
      {
        path: 'ads',
        name: 'ads',
        component: Ads,
      },
      {
        path: 'reports',
        name: 'reports',
        component: Analytics,
      },
      {
        path: 'request_new_format',
        name: 'request_new_format',
        component: RequestNewFormat,
      },
      {
        path: 'report_ad',
        name: 'report_ad',
        component: ReportAd,
      },
      {
        path: 'users',
        name: 'users',
        component: UsersList,
      },
      {
        path: 'users/new',
        name: 'new_user',
        component: NewUser
      },
      {
        path: 'users/:id?',
        name: 'user',
        component: User,
        props: route => ({ user: route.params.id }),
        children: [  
          {
            path: '',
            component: AccountProfile,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'info',
            name: 'user-info',
            component: AccountProfile,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'bank',
            name: 'user-bank',
            component: AccountBankAccount,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'credentials',
            name: 'user-credentials',
            component: AccountLogin,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'websites',
            name: 'user-manage',
            component: UserAssignedWesites,
            props: route => ({ user: route.params.id })
          },
        ]
      },
      {
        path: 'wallets',
        name: 'wallets',
        component: Wallets,
      },
      {
        path: 'wallets/:id?',
        name: 'wallet',
        component: Wallet,
      },
      {
        path: 'whitelist',
        name: 'white_list',
        component: Whitelist,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        children: [  
          {
            path: '',
            name: 'profile',
            component: AccountProfile
          },
          {
            path: 'info',
            name: 'profile-info',
            component: AccountProfile,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'bank',
            name: 'bank',
            component: AccountBankAccount,
            props: route => ({ user: route.params.id })
          },
          {
            path: 'credentials',
            name: 'credentials',
            component: AccountLogin,
            props: route => ({ user: route.params.id })
          },
        ]
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
      },
      {
        path: '/notifications/:id?',
        name: 'notification',
        component: Notifications
      },
      {
        path: '/messages',
        name: 'messages',
        component: Messages,
        children:[
          {
            path: ':id',
            name: 'message',
            component: Messages
          },
        ]
      },
      {
        path: 'logout',
        name: 'logout',
        component: ErrorDenied,
        beforeEnter: logoutUser,
      },
      {
        path: 'denied',
        name: 'denied',
        component: ErrorDenied,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'suspended',
        name: 'suspended',
        component: ErrorSuspended,
        meta: { 
          requiresAuth: true
        }
      },
      {
        // Error message for unavailable pages
        path: '*',
        name: 'Not Found',
        component: Error404,
        meta: { 
          requiresAuth: true
        }
      },
    ]
  },
]})
