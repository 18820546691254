import axios from "axios"
// import store from '@/store';

//const baseDomain = "/api/v1"
const baseDomain = "https://services.vidintop.com/api/v1"
const baseURL = `${baseDomain}`

// let token = store.getters.accessToken;

const instence = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 
        'Content-Type': 'application/json', 
        // "Authorization": `Bearer ${token}` 
    }
});

instence.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('accessToken')

        if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`
        }

        return config;
    }, 

    (error) => {
        return Promise.reject(error);
    }
);
// instence.interceptors.response.use(response => {
//     console.log('Response:', response)
//     return response
// })

export default instence