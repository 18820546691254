<template>
    <div class="grid grid-cols-12">
         <!--top bar-->
        <div class="col-span-12 sm:col-span-12">
            <div class="intro-y block sm:flex items-center justify-between box mt-5 p-5">
                <h3 class="text-lg font-medium truncate mr-5">List
                    <router-link :to="{name: 'new_user'}" class="">
                        <i data-feather="plus" class="inline"></i>
                    </router-link>
                </h3>
                <div class="search hidden sm:block" >
                    <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search">
                    <button  @click.prevent="loadSearchResult()"><i data-feather="search" class="search__icon"></i> </button>
                </div>           
            </div>
            <!--end of top bar --> 
            <!--table-->  
            <div class="intro-y overflow-auto  mt-8 sm:mt-0">
                <table class="table table-report sm:mt-2">
                    <thead>
                        <tr>
                            <th></th>
                            <th class=" whitespace-no-wrap">Full Name</th>
                            <th class=" whitespace-no-wrap">Username</th>
                            <th class=" whitespace-no-wrap">Type</th>
                            <th class=" whitespace-no-wrap">Email</th>
                            <th class=" whitespace-no-wrap">Status</th>
                            <th class="text-center whitespace-no-wrap">Verified</th>
                            <th class=" whitespace-no-wrap">Last login</th>
                            <th class="text-center whitespace-no-wrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!isLoaded">
                            <td colspan="9">
                                <point-spinner class="text-center" />
                            </td>
                        </tr>
                        <tr v-if="isLoaded && users.length == 0">
                            <td colspan="9" class="text-center">
                                No available users
                            </td>
                        </tr>
                        <tr v-for="user in users" :key="user.id">
                            <td>
                                <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
                                    <img class="img-circle list-img-circle elevation-2" v-if="user.profile" :src="user.profile.image || '/assets/img/Profile_avatar_placeholder.png'" alt="User Avatar">
                                    <img class="img-circle list-img-circle elevation-2" v-if="!user.profile" src="/assets/img/Profile_avatar_placeholder.png" alt="User Avatar">
                                </div>
                            </td>
                            <td>{{ user.first_name }} {{ user.last_name }}</td>
                            <td>{{ user.username }}</td>
                            <td> <span v-if="user.profile">{{ user.profile.user_role | capitalizeAndReplace }}</span> </td>
                            <td>{{ user.email }}</td>
                            <td>
                                <span class="alert btn-xs" :class="user.is_active? 'text-theme-9 block' : 'text-theme-6 block'"> 
                                    <span v-if="user.is_active"> Active </span>
                                    <span v-if="!user.is_active"> Suspended </span>
                                </span>
                            </td>
                            <td class="text-center">
                                <span v-if="user.profile"> 
                                    <i class="fa x_large_icon" :class="user.profile.is_verified? 'fa-check-circle success' : 'fa-times-circle warning' "></i>
                                </span>
                            </td>
                            <td>{{ user.last_login }}</td>
                            <td class="text-center">
                                <div class="btn-group btn-group-sm flex flex-row">
                                    <router-link class="py-1 px-2 rounded-md text-xs text-white cursor-pointer font-medium bg-theme-3 flex flex-row items-baseline " 
                                    :to="{name: 'user-info', params: {id: user.id}}"><fa-icon :icon="['fa', 'edit']" class="mx-1" /> Edit</router-link>
                                    <router-link class="py-1 px-2 rounded-md text-xs cursor-pointer font-medium bg-theme-2 dark:bg-theme-16 flex flex-row ml-1 items-baseline" 
                                    :to="{name: 'wallets', query: {account: user.id}}"><fa-icon :icon="['fa', 'wallet']" class="mx-1" /> Wallet</router-link>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr v-if="users.length <= 0">
                            <td>
                                <h5 class="text-center">No data available currently.</h5>
                            </td>
                        </tr>
                        -->
                    </tbody>
                </table>
            </div>
            <!--end of table--> 
            <!--pagination-->
            <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
                <ul class="pagination">
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                    </li>
                    <li class="">
                        <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                    </li>
                    <li class="">
                        <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                    </li>
                </ul>
            </div> 
            <!--pagination--> 
        </div>  
    </div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory';
const UsersRepository = RepositoryFactory.get('users');

export default {
    data() {
        return {
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            users: [],

            search_text: "",
            isLoaded: true,
            hasError: false
        }
    },
    created: function() {
        this.loadUsers()
        this.$feather.replace()
    },
    mounted: function(){
        this.$feather.replace()
    },
    methods: {
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadUsers()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadUsers()
            }
        },
        loadUsers: function() {
            this.isLoaded = false
            UsersRepository.page(this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
                this.isLoaded = true
                this.hasError = false
            }).catch(err => {
                this.isLoaded = true
                this.hasError = true
                this.users = []
            })
        },
        loadSearchResult: function() {
            UsersRepository.page(this.currentPage, this.resultsLimit, this.search_text).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
            }).catch(err => {
                this.users = []
            })
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>
.list-img-circle {
    height: 45px;
}
.warning {
    color: #ffc107;

}
.success {
    color: #1e7e34;
}
.x_large_icon {
    font-size: x-large;
}
</style>