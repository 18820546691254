<template>
    <div class="h-screen flex flex-col items-center" >
        <div class="box p-6 mt-12 w-full sm:w-4/6 " >
            <div class="flex flex-col-reverse sm:flex-row text-gray-600 border-b border-gray-200 dark:border-dark-1">
                <div class="flex items-center mt-3 sm:mt-0 border-t sm:border-0 border-gray-200 pt-5 sm:pt-0 mt-5 sm:mt-0 -mx-5 sm:mx-0 px-5 sm:px-0">
                    <p class="intro-x p-2 text-lg sm:text-2xl text-left">Domain</p>
                </div>
                <div class="flex items-center sm:ml-auto">
                    <div v-if="whitelabel.id" class="py-2 px-2 rounded text-xs text-sm ml-auto truncate"
                    :class="{
                        'bg-red-600 text-white dark:text-white': whitelabel.status === 'suspended',
                        'bg-orange-500 text-white dark:text-white': whitelabel.status === 'pending',
                        'bg-green-500 text-white dark:text-white': whitelabel.status === 'active'
                    }">
                        {{ whitelabel.status | capitalize }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="flex flex-col mr-10">
                    <ul class="flex border-b mt-5">
                        <li class="-mb-px mr-1">
                            <a @click="lightTab = true" :class="classObjectLight"  href="#">Light</a>
                        </li>
                        <li class="mr-1">
                            <a @click="lightTab = false" :class="classObjectDark"  href="#">Dark</a>
                        </li>
                    </ul>
                    <!-- upload light -->
                    <div class="flex flex-col pt-6" v-if="lightTab">
                        <div class="w-64 h-32 image-fit">
                            <input type="file" ref="file" @change="onChangeFileUpload" style="display: none;" />
                            <img alt="User" class="cursor-pointer zoom-in" :src="whitelabel.logo || imageLightPath" @click="$refs.file.click()">
                        </div>
                        <button @click.prevent="onUpload"
                            class="bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mt-5">
                            Upload
                        </button>
                    </div>
                    <!-- upload light -->

                    <!-- upload Dark -->
                    <div class="flex flex-col pt-6" v-else>
                        <div class="w-64 h-32 image-fit">
                            <input type="file" ref="file" @change="onChangeFileUpload('dark')" style="display: none;" />
                            <img alt="User" class="cursor-pointer zoom-in" :src="whitelabel.logo_dark || imageDarkPath" @click="$refs.file.click()">
                        </div>
                        <button @click.prevent="onUpload('dark')"
                            class="bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mt-5">
                            Upload
                        </button>
                    </div>
                    <!-- upload Dark -->
                </div>
                <div class="flex flex-col w-full">
                    <div class="flex flex-col pt-6">
                        <float-label>
                            <input type="email" v-model="whitelabel.domain" class="input pl-2 w-full border " placeholder="Domain name">
                        </float-label>
                    </div>
                    <div class="flex flex-col pt-6">
                        <float-label>
                            <input type="company_name" v-model="whitelabel.company_name" class="input pl-2 w-full border " placeholder="Company Name">
                        </float-label>
                    </div>
                    <div class="flex flex-col pt-6">
                        <float-label>
                            <input type="terms_link" v-model="whitelabel.terms_link" class="input pl-2 w-full border " placeholder="Terms of Service Link">
                        </float-label>
                    </div>
                    <div class="flex flex-col pt-6">
                        <float-label>
                            <input type="privacy_link" v-model="whitelabel.privacy_link" class="input pl-2 w-full border " placeholder="Privcy Policy Link">
                        </float-label>
                    </div>
                    <div class="flex flex-col pt-6">
                    <div class="dark-mode-switcher p-2 border-t dark:border-gray-700">
                        <div class="input--switch flex items-center p-2 w-full text-gray-700 dark:text-gray-300 rounded-md transition duration-300 ease-in-out ">
                            Allow Signup
                            <input type="checkbox" class="m-1 ml-auto input input--switch border" v-model="whitelabel.allow_signup">
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <button @click.prevent="submit"
                class="float-right bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mt-5">
                <i data-feather="edit"></i>
            </button>
        </div>
    </div>
</template>

<script>
import { isValidDomain } from '@/validators/domain'
import { RepositoryFactory } from '@/services/repositories/NetworkAPI/RepositoryFactory'
const WhitelistRepo = RepositoryFactory.get('whitelist')

export default {
    components: {
        
    },
    data() {
        return {
            whitelabel: {
                domain: "",
            },
            selectedLightFile: null,
            selectedDarkFile: null,
            imageLightPath: '/assets/images/profile-7.jpg',
            imageDarkPath: '/assets/images/profile-7.jpg',
            errors: [],
            lightTab: true
        }
    },
    created() {
        this.loadWhitelists()
    },
    mounted() {
        this.$feather.replace()
    },
    methods: {
        loadWhitelists: function() {
            WhitelistRepo.get(this.user.id).then(result => {
                this.whitelabel = result.data
            }).catch(err => {})
        },
        submit: function() {
            if(isValidDomain(this.whitelabel.domain)) {
                this.errors = []
                // this.website.network = this.user.id
                if(this.whitelabel.id) {
                    this.edit()
                } else {
                    this.add()
                }
            } else {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Error',
                    text: 'Invalid domain.'
                })
            }
        },
        add: function() {
            WhitelistRepo.post(this.whitelabel).then(result => {
                this.whitelabel = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Success',
                    text: 'Successfuly submite your domain.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to submite your domain.'
                })
            })
        },
        edit: function() {
            WhitelistRepo.patch(this.user.id, this.whitelabel).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Suceess',
                    text: 'Successfuly submited your domain.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to update domain.'
                })
            })
        },
        onUpload(theme) {
            let formData = new FormData();
            if(theme == 'dark') {
                formData.append(theme, this.selectedFile, this.selectedFile.name);
            }
            WhitelistRepo.upload(this.user.id, formData).then(result => {
                console.log(result)
            }).catch(err => {
                console.log(err)
            })
        },
        onChangeFileUpload(theme) {
            if(theme == 'dark') {
                this.selectedDarkFile = this.$refs.file.files[0];
                this.imageDarkPath = URL.createObjectURL(this.selectedDarkFile);
            } else {
                this.selectedLightFile = this.$refs.file.files[0];
                this.imageLightPath = URL.createObjectURL(this.selectedLightFile);
            }
        },
        onUploadProgress() {
            
        },
    },
    computed: {
        user: function() {
            return this.$store.getters.user
        },
        classObjectLight:function() {
            return {
                'bg-white inline-block py-2 px-4 font-semibold border-l border-t border-r rounded-t text-blue-700': this.lightTab,
                'bg-white inline-block py-2 px-4 font-semibold text-blue-500 hover:text-blue-800': !this.lightTab
            }
        },        
        classObjectDark:function() {
            return {
                'bg-white inline-block py-2 px-4 font-semibold border-l border-t border-r rounded-t text-blue-700': !this.lightTab,
                'bg-white inline-block py-2 px-4 font-semibold text-blue-500 hover:text-blue-800': this.lightTab
            }
        }
    },
    watch: {
    }
}
</script>
<style scoped>

</style>